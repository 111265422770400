import useGlobal from '../hooks/useGlobal'

const Header = () => {
  const { steps, goToStep } = useGlobal()

  const stepAvailable = (step) => {
    if (steps[step].status === 'complete') {
      return true
    }
    if (step > 0) {
      return steps[step - 1].status === 'complete'
    }
    return false
  }

  const stepIcon = (step) => {
    switch (steps[step].status) {
      case 'incomplete':
        return 'border-[#d9d9d9]'
      case 'in-progress':
        return 'border-[#D6B42C]'
      case 'complete':
        return 'border-[#3DB16F]'
      default:
        return 'border-[#d9d9d9]'
    }
  }

  const stepIconBg = (step) => {
    switch (steps[step].status) {
      case 'incomplete':
        return 'bg-[#d9d9d9]'
      case 'in-progress':
        return 'bg-[#D6B42C]'
      case 'complete':
        return 'bg-[#3DB16F]'
      default:
        return 'bg-[#d9d9d9]'
    }
  }

  const statusTitle = (status) => {
    if (status === 'incomplete') {
      return 'Incomplete'
    }
    if (status === 'in-progress') {
      return 'In Progress'
    }
    if (status === 'complete') {
      return 'Complete'
    }
  }

  return (
    <>
      <header className="w-full bg-primary min-h-[121px] px-4 text-center py-10 flex justify-center items-center">
        <h1 className="text-2xl lg:text-4xl font-bold text-white">
          Your Personalised SAS Quote
        </h1>
      </header>
      <ul className="flex lg:hidden justify-center space-x-4 mt-8">
        {steps?.map((step, index) => {
          if (index === 0 || index === 5) return null
          return (
            <li
              onClick={() => {
                if (stepAvailable(index)) {
                  goToStep(step.id)
                }
              }}
              key={index}
              title={statusTitle(step.status)}
              className={`border-4 ${stepIcon(
                index
              )} w-12 h-12 p-0 rounded-full flex justify-center items-center bg-white ${
                step.current ? stepIconBg(index) : ''
              } ${
                stepAvailable(index) ? 'cursor-pointer hover:bg-[#e9e9e9]' : ''
              }`}
            >
              <span className="font-bold">{index}</span>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default Header
