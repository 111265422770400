/* eslint-disable */
import useGlobal from '../hooks/useGlobal'
import { useState, Fragment } from 'react'

import ToolTip from '../components/ToolTip'

import { Dialog, Transition } from '@headlessui/react'

const Step3 = () => {
  const { quote, setQuote, saveProgress, goToStep } = useGlobal()

  const [isOpen, setIsOpen] = useState(false)
  const [isFTEOpen, setIsFTEOpen] = useState(false)
  const [showUpdateAll, setShowUpdateAll] = useState(false)
  const [errorMsg, setErrorMsg] = useState([])
  const [staffFTECalculator, setStaffFTECalculator] = useState([
    { calculatorHours: 37, calculatorWorked: 30 },
    {
      calculatorHours: 32.5,
      calculatorWorked: 389,
    },
  ])
  const [updateAllItems, setUpdateAllItems] = useState([])

  const closeModal = () => {
    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const closeFTEModal = () => {
    setIsFTEOpen(false)
  }

  const openFTEModal = () => {
    setIsFTEOpen(true)
  }

  const nextStep = async (e) => {
    e.preventDefault()
    let failed = false
    let msg = []

    let tempArr = [...quote.schools]

    tempArr.forEach((school, schoolIndex) => {
      msg[schoolIndex] = { name: school.name, errors: [] }
      school.items.forEach((item, itemIndex) => {
        msg[schoolIndex].errors[itemIndex] = {
          name: item.staffType,
          errors: [],
        }
        if (item.fte <= 0) {
          failed = true
          msg[schoolIndex].errors[itemIndex].errors.push(
            'Please make sure all FTE amounts are greater than 0'
          )
        }
        if (item.benefit > 300) {
          failed = true
          msg[schoolIndex].errors[itemIndex].errors.push(
            'The maximum daily benefit allowed is £300'
          )
          item.benefit = 300
        }

        if (item.benefit < 30) {
          failed = true
          msg[schoolIndex].errors[itemIndex].errors.push(
            'The minimum daily benefit allowed is £30'
          )
          item.benefit = 30
        }
        if (item.period === '260' && item.benefit > 223) {
          failed = true
          msg[schoolIndex].errors[itemIndex].errors.push(
            'The maximum daily benefit allowed is £223'
          )
          item.benefit = 223
        }
        if (item.benefit <= 0) {
          failed = true
          msg[schoolIndex].errors[itemIndex].errors.push(
            'Please make sure all Daily Benefit amounts are greater than 0'
          )
        }
        if (
          item.excess === '--' ||
          item.period === '--' ||
          item.stress === '--'
        ) {
          failed = true
          msg[schoolIndex].errors[itemIndex].errors.push(
            'Please make sure you select an option from the dropdown menus'
          )
        }
      })
    })

    setQuote({ ...quote, schools: tempArr })

    if (failed) {
      setErrorMsg(msg)

      openModal()
      return
    }
    save()
  }

  const updateList = () => {
    let maxItems = []
    let quoteSchools = [...quote.schools]
    quoteSchools.forEach((school) => {
      if (school.items.length > maxItems.length) {
        maxItems = [...school.items]
      }
    })
    setUpdateAllItems(maxItems)
  }

  const save = () => {
    saveProgress()
    goToStep(4, 'complete')
  }

  const updateMaternity = (e, school, item) => {
    let val = e.target.checked

    let schools = [...quote.schools]

    if (val === true) {
      schools[school].items[item].maternityCover = 2000
      schools[school].items[item].maternity = '0.00'
    } else {
      schools[school].items[item].maternityCover = 'Excluded'
      schools[school].items[item].maternity = '0.00'
    }

    setQuote({ ...quote, schools: schools })
  }

  const openFteCalc = () => {
    openFTEModal()
  }

  const updateEverything = () => {
    let temp = [...quote.schools]
    temp.forEach((school) => {
      updateAllItems.forEach((item, index) => {
        if (school.items[index]) {
          school.items[index].benefit = { ...item }.benefit
          school.items[index].excess = { ...item }.excess
          school.items[index].period = { ...item }.period
          school.items[index].stress = { ...item }.stress
        }
      })
    })

    setQuote({ ...quote, schools: temp })
  }

  return (
    <div className="w-full flex flex-col items-center px-2 md:px-0 py-6 md:py-12">
      <h2 className="text-font-primary font-bold text-xl sm:text-2xl lg:text-3xl mb-8 text-center relative">
        Level of cover per staff category
      </h2>

      {quote.type === 'multi' && (
        <>
          <div className="flex w-full justify-start items-center px-8 mt-4 mb-8">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={showUpdateAll}
                className="sr-only peer"
                id="update-all-schools"
                onChange={(e) => {
                  updateList()
                  setShowUpdateAll(e.target.checked)
                }}
              />
              <div className="w-11 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
            </label>
            <label
              htmlFor="update-all-schools"
              className="ml-4 text-sm md:text-base lg:text-lg cursor-pointer"
            >
              Update all Schools/Academies at once
            </label>
          </div>

          {showUpdateAll && (
            <div className="bg-white w-full py-6 px-8 mb-8">
              <div>
                <h3 className="text-primary font-semibold text-sm md:text-base lg:text-lg">
                  Update All School Values
                </h3>
                <div className="hidden xl:flex w-full mb-3 mt-4 space-x-2">
                  <div className="w-2/12 sm:pr-4 xl:pr-2">
                    <label className="text-sm xl:text-base font-medium ">
                      Category
                    </label>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/12 sm:pr-4 xl:pr-2">
                    <label className="relative text-sm xl:text-base font-medium">
                      FTE
                      <div onClick={openFteCalc}>
                        <ToolTip
                          text="Click the icon to view our FTE Calculator"
                          icon="right"
                          popup="center"
                        />
                      </div>
                    </label>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/12 sm:pr-4 xl:pr-2">
                    <label className="text-sm xl:text-base font-medium">
                      Daily Benefit £
                    </label>
                  </div>
                  <div className="w-2/12 sm:pr-4 xl:pr-2">
                    <label className="relative text-sm xl:text-base font-medium">
                      Excess Period
                      <ToolTip
                        text="The amount of absent days before you can make a claim."
                        icon="right"
                        popup="center"
                      />
                    </label>
                  </div>
                  <div className="w-2/12 sm:pr-4 xl:pr-2">
                    <label className="text-sm xl:text-base font-medium">
                      Benefit Period
                    </label>
                  </div>
                  <div className="w-2/12 sm:pr-4 xl:pr-2">
                    <label className="text-sm xl:text-base font-medium">
                      Stress Cover
                    </label>
                  </div>
                </div>
                {updateAllItems.map((item, itemIndex) => {
                  return (
                    <div>
                      <div
                        className="flex w-full mb-3 xl:space-x-2 flex-wrap xl:flex-nowrap relative"
                        key={itemIndex}
                      >
                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-2/12 sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              Category
                            </label>
                            <input
                              type="text"
                              disabled
                              name="staff_type"
                              className="cm-input disabled"
                              defaultValue={item.staffType}
                            />
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/12 sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              FTE
                            </label>
                            <input
                              type="number"
                              step="0.01"
                              name="staff_type"
                              disabled
                              required
                              className="cm-input disabled"
                              min={0}
                              value={'0.00'}
                            />
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/12 sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              Daily Benefit £
                            </label>
                            <input
                              type="number"
                              step="1"
                              required
                              name="staff_type"
                              className="cm-input"
                              value={item.benefit}
                              onChange={(e) => {
                                let val = e.target.value
                                let itemList = [...updateAllItems]
                                itemList[itemIndex].benefit = val
                                setUpdateAllItems(itemList)
                                updateEverything()
                              }}
                            />
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-2/12 sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              Excess Period
                            </label>
                            <select
                              required
                              id="excess_period"
                              name="excess_period"
                              className="cm-input"
                              defaultValue={item.excess}
                              onChange={(e) => {
                                let val = e.target.value
                                let itemList = [...updateAllItems]
                                itemList[itemIndex].excess = val
                                setUpdateAllItems(itemList)
                                updateEverything()
                              }}
                            >
                              <option value="--" disabled>
                                Select an option
                              </option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="15">15</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                            </select>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-2/12 sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              Benefit Period
                            </label>
                            <select
                              required
                              id="benefit_period"
                              name="benefit_period"
                              className="cm-input"
                              defaultValue={item.period}
                              onChange={(e) => {
                                let val = e.target.value
                                let itemList = [...updateAllItems]
                                itemList[itemIndex].period = val
                                setUpdateAllItems(itemList)
                                updateEverything()
                              }}
                            >
                              <option value="--" disabled>
                                Select an option
                              </option>
                              <option value="190">190</option>
                              <option value="260">260</option>
                            </select>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-2/12 sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              Stress Cover
                            </label>
                            <select
                              required
                              id="stress_period"
                              name="stress_period"
                              className="cm-input"
                              defaultValue={item.stress}
                              onChange={(e) => {
                                let val = e.target.value
                                let itemList = [...updateAllItems]
                                itemList[itemIndex].stress = val
                                setUpdateAllItems(itemList)
                                updateEverything()
                              }}
                            >
                              <option value="--" disabled>
                                Select an option
                              </option>
                              <option value="0">0</option>
                              <option value="30">30</option>
                              <option value="190">190</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {itemIndex !== updateAllItems.length - 1 && (
                        <hr className="xl:hidden mt-8 mb-6" />
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </>
      )}

      <form
        className="flex flex-col justify-center px-8 w-full "
        onSubmit={nextStep}
      >
        {quote.schools.map((school, index) => {
          return (
            <div className="w-full mb-8" key={index}>
              <h3 className="text-primary font-semibold text-xl">
                {school.name}
              </h3>

              <div>
                <div className="hidden xl:flex w-full mb-3 mt-4 space-x-2">
                  <div className="w-full md:w-1/2 lg:w-1/4 xl:w-2/12 sm:pr-4 xl:pr-2">
                    <label className="text-sm xl:text-base font-medium ">
                      Category
                    </label>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/12 sm:pr-4 xl:pr-2">
                    <label className="relative text-sm xl:text-base font-medium">
                      FTE
                      <div onClick={openFteCalc}>
                        <ToolTip
                          text="Click the icon to view our FTE Calculator"
                          icon="right"
                          popup="center"
                        />
                      </div>
                    </label>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/12 sm:pr-4 xl:pr-2">
                    <label className="text-sm xl:text-base font-medium">
                      Daily Benefit £
                    </label>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/4 xl:w-2/12 sm:pr-4 xl:pr-2">
                    <label className="relative text-sm xl:text-base font-medium">
                      Excess Period
                      <ToolTip
                        text="The amount of absent days before you can make a claim."
                        icon="right"
                        popup="center"
                      />
                    </label>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/4 xl:w-2/12 sm:pr-4 xl:pr-2">
                    <label className="text-sm xl:text-base font-medium">
                      Benefit Period
                    </label>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/4 xl:w-2/12 sm:pr-4 xl:pr-2">
                    <label className="text-sm xl:text-base font-medium">
                      Stress Cover
                    </label>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/12 sm:pr-4 xl:pr-2">
                    <label className="text-sm xl:text-base font-medium">
                      Maternity Included
                    </label>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/12 sm:pr-4 xl:pr-2">
                    <label className="text-sm xl:text-base font-medium">
                      Maternity Benefit £
                    </label>
                  </div>
                </div>
                {school.items.map((item, itemIndex) => {
                  return (
                    <div>
                      <div
                        className="flex w-full mb-3 xl:space-x-2 flex-wrap xl:flex-nowrap relative"
                        key={itemIndex}
                      >
                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-2/12 sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              Category
                            </label>
                            <input
                              type="text"
                              disabled
                              name="staff_type"
                              className="cm-input disabled"
                              defaultValue={item.staffType}
                            />
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/12 sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              FTE
                            </label>
                            <input
                              type="number"
                              step="0.01"
                              name="staff_type"
                              required
                              className="cm-input"
                              min={0}
                              value={item.fte}
                              onChange={(e) => {
                                let val = Number(e.target.value)
                                let schools = [...quote.schools]
                                if (val <= 0) {
                                  val = 0.0
                                }
                                schools[index].items[itemIndex].fte = val
                                setQuote({ ...quote, schools: schools })
                              }}
                            />
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/12 sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              Daily Benefit £
                            </label>
                            <input
                              type="number"
                              step="1"
                              required
                              name="staff_type"
                              className="cm-input"
                              value={item.benefit}
                              onChange={(e) => {
                                let val = e.target.value
                                let schools = [...quote.schools]
                                schools[index].items[itemIndex].benefit = val
                                setQuote({ ...quote, schools: schools })
                              }}
                            />
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-2/12 sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              Excess Period
                            </label>
                            <select
                              required
                              id="excess_period"
                              name="excess_period"
                              className="cm-input"
                              value={item.excess}
                              onChange={(e) => {
                                let val = e.target.value
                                let schools = [...quote.schools]
                                if (val <= 0) {
                                  val = 0.0
                                }
                                schools[index].items[itemIndex].excess = val
                                setQuote({ ...quote, schools: schools })
                              }}
                            >
                              <option value="--" disabled>
                                Select an option
                              </option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="15">15</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                            </select>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-2/12 sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              Benefit Period
                            </label>
                            <select
                              required
                              id="benefit_period"
                              name="benefit_period"
                              className="cm-input"
                              value={item.period}
                              onChange={(e) => {
                                let val = e.target.value
                                let schools = [...quote.schools]
                                if (val <= 0) {
                                  val = 0.0
                                }
                                schools[index].items[itemIndex].period = val
                                setQuote({ ...quote, schools: schools })
                              }}
                            >
                              <option value="--" disabled>
                                Select an option
                              </option>
                              <option value="190">190</option>
                              <option value="260">260</option>
                            </select>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-2/12 sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              Stress Cover
                            </label>
                            <select
                              required
                              id="stress_period"
                              name="stress_period"
                              className="cm-input"
                              value={item.stress}
                              onChange={(e) => {
                                let val = e.target.value
                                let schools = [...quote.schools]
                                if (val <= 0) {
                                  val = 0.0
                                }
                                schools[index].items[itemIndex].stress = val
                                setQuote({ ...quote, schools: schools })
                              }}
                            >
                              <option value="--" disabled>
                                Select an option
                              </option>
                              <option value="0">0</option>
                              <option value="30">30</option>
                              <option value="190">190</option>
                            </select>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/12 sm:pr-4 xl:pr-2 flex items-center">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              Maternity Included
                            </label>
                            <label className="relative inline-flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                checked={item.maternityCover !== 'Excluded'}
                                className="sr-only peer"
                                onChange={(e) =>
                                  updateMaternity(e, index, itemIndex)
                                }
                              />
                              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
                            </label>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/12 flex items-center sm:pr-4 xl:pr-2">
                          <div className="input-group">
                            <label htmlFor="" className="block xl:hidden mt-2 ">
                              Maternity Benefit £
                            </label>
                            {item.maternityCover !== 'Excluded' ? (
                              <select
                                required
                                id="stress_period"
                                name="stress_period"
                                className="cm-input"
                                defaultValue={item.maternityCover}
                                onChange={(e) =>
                                  (item.maternityCover = e.target.value)
                                }
                              >
                                <option>500</option> <option>1000</option>{' '}
                                <option>1500</option> <option>2000</option>{' '}
                                <option>2500</option> <option>3000</option>{' '}
                                <option>3500</option> <option>4000</option>{' '}
                                <option>4500</option> <option>5000</option>{' '}
                                <option>5500</option> <option>6000</option>{' '}
                                <option>6500</option> <option>7000</option>{' '}
                                <option>7500</option> <option>8000</option>{' '}
                                <option>8500</option> <option>9000</option>{' '}
                                <option>9500</option> <option>10000</option>{' '}
                                <option>Included</option>
                              </select>
                            ) : (
                              <span className="text-xs bg-red-400 py-1 text-white rounded flex items-center justify-center">
                                Not Included
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      {itemIndex !== school.items.length - 1 && (
                        <hr className="xl:hidden mt-8 mb-6" />
                      )}
                    </div>
                  )
                })}
              </div>

              {index !== quote.schools.length - 1 && <hr className="mt-10" />}
            </div>
          )
        })}

        <button className="btn w-full bg-primary hover:bg-primary-hover text-white max-w-3xl mx-auto">
          Next: Start Date
        </button>
      </form>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 py-8 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl sm:text-2xl lg:text-3xl font-bold leading-6 text-slate-800 mb-6"
                  >
                    You have errors with your cover
                  </Dialog.Title>
                  <div className="mt-2 overflow-y-scroll max-h-[500px]">
                    {errorMsg.map((school) => {
                      let show = false
                      school.errors.forEach((item) => {
                        if (item.errors.length > 0) {
                          show = true
                        }
                      })
                      if (show) {
                        return (
                          <>
                            <p className="text-primary text-base md:text-lg font-bold">
                              {school.name}
                            </p>
                            {school.errors.map((item) => {
                              return (
                                <>
                                  {item.errors.length > 0 && (
                                    <p className="text-sm md:text-base font-bold">
                                      {item.name}
                                    </p>
                                  )}
                                  <ul className="list-disc list-inside">
                                    {item.errors.map((error) => {
                                      return (
                                        <li className="text-xs sm:text-sm md:text-base">
                                          {error}
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </>
                              )
                            })}
                          </>
                        )
                      }
                      return null
                    })}
                  </div>

                  <div className="mt-8 flex justify-end">
                    <button
                      className="btn bg-blue-500 hover:bg-blue-600 text-white"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isFTEOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeFTEModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 py-8 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-3xl font-bold leading-6 text-slate-800 mb-4"
                  >
                    FTE Calculator
                  </Dialog.Title>
                  <div className="">
                    <p className="text-sm text-blue-500 mb-8">
                      Use the below information as guidance on how to calculate
                      your FTE values, then insert this into the quote form
                    </p>
                    <div className="flex w-full space-x-8">
                      <div className="w-1/2 rounded bg-slate-100 border border-slate-200 shadow p-4">
                        <h3 className="text-lg text-center font-bold">
                          An Individual FTE
                        </h3>
                        <hr className="my-4 border-gray-300" />
                        <div className="mb-4">
                          <label
                            htmlFor="fte1-hours_worked"
                            className="text-sm"
                          >
                            Individuals hours worked per week
                          </label>
                          <input
                            type="text"
                            required
                            id="fte1-hours_worked"
                            name="fte1-hours_worked"
                            className="cm-input"
                            defaultValue={
                              staffFTECalculator[0].calculatorWorked
                            }
                            onChange={(e) => {
                              let temp = [...staffFTECalculator]
                              temp[0].calculatorWorked = e.target.value
                              setStaffFTECalculator(temp)
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="fte1-hours_worked"
                            className="text-sm"
                          >
                            Full Time Hours
                          </label>
                          <input
                            type="text"
                            required
                            id="fte1-calculatorHours"
                            name="fte1-calculatorHours"
                            className="cm-input"
                            defaultValue={staffFTECalculator[0].calculatorHours}
                            onChange={(e) => {
                              let temp = [...staffFTECalculator]
                              temp[0].calculatorHours = e.target.value
                              setStaffFTECalculator(temp)
                            }}
                          />
                        </div>
                        <div className="mb-4 mt-8 block relative">
                          <label
                            htmlFor="fte1-hours_worked"
                            className="text-sm"
                          >
                            Calculated FTE
                          </label>
                          <input
                            type="text"
                            disabled
                            id="fte1-calculatorFte"
                            name="fte1-calculatorFte"
                            className="cm-input blue"
                            value={(
                              staffFTECalculator[0].calculatorWorked /
                              staffFTECalculator[0].calculatorHours
                            ).toFixed(2)}
                          />
                        </div>

                        <p className="text-sm text-center">
                          Eg. Sally works 30 hrs per week. Full time hours are
                          37 hours per week.
                        </p>
                      </div>

                      <div className="w-1/2 rounded bg-slate-100 border border-slate-200 shadow p-4">
                        <h3 className="text-lg text-center font-bold">
                          Total staff type FTE
                        </h3>
                        <hr className="my-4 border-gray-300" />
                        <div className="mb-4">
                          <label
                            htmlFor="fte1-hours_worked"
                            className="text-sm"
                          >
                            Total hours worked by staff type
                          </label>
                          <input
                            type="text"
                            required
                            id="fte1-hours_worked"
                            name="fte1-hours_worked"
                            className="cm-input"
                            defaultValue={
                              staffFTECalculator[1].calculatorWorked
                            }
                            onChange={(e) => {
                              let temp = [...staffFTECalculator]
                              temp[1].calculatorWorked = e.target.value
                              setStaffFTECalculator(temp)
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="fte1-hours_worked"
                            className="text-sm"
                          >
                            Full Time Hours
                          </label>
                          <input
                            type="text"
                            required
                            id="fte1-calculatorHours"
                            name="fte1-calculatorHours"
                            className="cm-input"
                            defaultValue={staffFTECalculator[1].calculatorHours}
                            onChange={(e) => {
                              let temp = [...staffFTECalculator]
                              temp[1].calculatorHours = e.target.value
                              setStaffFTECalculator(temp)
                            }}
                          />
                        </div>
                        <div className="mb-4 mt-8 block relative">
                          <label
                            htmlFor="fte1-hours_worked"
                            className="text-sm"
                          >
                            Calculated FTE
                          </label>
                          <input
                            type="text"
                            disabled
                            id="fte1-calculatorFte"
                            name="fte1-calculatorFte"
                            className="cm-input blue"
                            value={(
                              staffFTECalculator[1].calculatorWorked /
                              staffFTECalculator[1].calculatorHours
                            ).toFixed(2)}
                          />
                        </div>

                        <p className="text-sm text-center">
                          Eg. Total hours worked by all the teachers is 389.
                          full time hours are 32.5
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 flex justify-end">
                    <button
                      className="btn bg-blue-500 hover:bg-blue-600 text-white"
                      onClick={closeFTEModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default Step3
