import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ToolTip = ({ text, icon, popup }) => {
  const iconPos = () => {
    if (icon === 'right') {
      return '-right-7'
    }
    if (icon === 'left') {
      return '-left-7'
    }
  }

  const popupPos = () => {
    if (popup === 'right') {
      return '-right-7'
    }
    if (popup === 'left') {
      return '-left-7'
    }
    if (popup === 'center') {
      return ''
    }
  }

  return (
    <div
      className={`hidden md:flex group absolute ${iconPos()} -top-3 justify-center z-30`}
    >
      <span className="border-primary border rounded-full w-6 h-6 flex justify-center items-center text-sm cursor-pointer">
        <FontAwesomeIcon icon={faInfo} className="text-xs text-primary" />
      </span>
      <span
        className={`absolute top-8 scale-0 transition-all font-normal rounded bg-gray-800 p-2 text-sm text-white group-hover:scale-100 ${popupPos()} min-w-[300px]`}
      >
        {text}
      </span>
    </div>
  )
}

export default ToolTip
