/* eslint-disable */
import useGlobal from '../hooks/useGlobal'

import ToolTip from '../components/ToolTip'

const Starter = () => {
  const { progress, quote, goToStep } = useGlobal()

  const nextStep = (type) => {
    quote.type = type
    if (type !== 'multi') {
      progress.contact_type = 'Academy'
    } else {
      progress.contact_type = 'Trust'
    }

    save()
  }

  const save = () => {
    goToStep(1, 'complete')
  }

  return (
    <div className="w-full flex flex-col items-center px-2 md:px-0 py-6 md:py-12">
      <h2 className="text-font-primary font-bold text-xl lg:text-3xl mb-8 lg:mb-12">
        Get a Quote for...
      </h2>

      <div className="flex flex-col md:flex-row justify-center space-y-6 w-full md:max-w-2xl px-0 sm:px-8 md:px-0 md:space-y-0 md:space-x-12 ">
        <div
          onClick={() => nextStep('single')}
          className="relative cursor-pointer text-center bg-primary hover:bg-primary-hover transition-all duration-200 rounded-md text-white text-lg md:text-xl font-bold w-full lg:w-80 py-6 md:py-10 flex justify-center items-center"
        >
          Individual School <br />
          or <br />
          Academy Policy
          <ToolTip
            text="Get a quote for an individual school or academy"
            icon="left"
          />
        </div>

        <div
          onClick={() => nextStep('multi')}
          className="relative text-center cursor-pointer bg-primary hover:bg-primary-hover transition-all duration-200 rounded-md text-white text-lg md:text-xl font-bold w-full lg:w-80 py-6 md:py-10 flex justify-center items-center"
        >
          Multi Academy Trust <br />
          or <br />
          Group Policy
          <ToolTip
            text="Get a quote for all schools or academies tailored to your individual needs & benefit from a group discount"
            icon="right"
          />
        </div>
      </div>
    </div>
  )
}

export default Starter
