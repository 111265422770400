import { Outlet } from 'react-router-dom'

function Layout() {
  return (
    <main className="App bg-[#E7E7E8]">
      <Outlet />
    </main>
  )
}

export default Layout
